import { Page } from 'Components'
import React from 'react'
// import styled from 'styled-components'
// const digging_pic =
//   'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Woman_with_a_digging_stick_%2810795493376%29.jpg/800px-Woman_with_a_digging_stick_%2810795493376%29.jpg'

// const Image = styled(Paper)`
//   height: 50vh;
// `
const Declarative = () => {
  return <Page title='-RE CASE'></Page>
}

export default Declarative
