import { Feedback, Page } from 'Components'
import React from 'react'

// TODO: Implement request feature
const Request = () => {
  return (
    <Page>
      <Feedback />
    </Page>
  )
}

export default Request
