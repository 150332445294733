const blankState = {
  language_entry: '',
  pronunciation: [],
  alternative_spellings: [],
  translations: [],
  tags: [],
  images: [],
  recordings: [],
  notes: [],
  roles: [],
  public: false,
}

export default blankState
